<form [formGroup]="locationForm" (submit)="onSubmit()" novalidate class="striped-rows">

  <div class="form-group row">
    <label class="col-md-3 label-control" for="nickname">{{'Nickname' | translate}}</label>
    <div class="col-md-9">
      <input type="text" id="nickname" class="form-control" name="nickname" placeholder="nickname"
        formControlName="nickname">
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-3 label-control" for="addressLine1">{{ 'Address Line 1' | translate}}</label>
    <div class="col-md-9">
      <input type="text" id="addressLine1" class="form-control" name="addressLine1" placeholder="addressLine1"
        formControlName="addressLine1">
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-3 label-control" for="addressLine2">{{'Address Line 2' | translate}}</label>
    <div class="col-md-9">
      <input type="text" id="addressLine2" class="form-control" name="addressLine2" placeholder="addressLine2"
        formControlName="addressLine2">
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-3 label-control" for="cityId">{{'City' | translate}}</label>
    <div class="col-md-9">
      <ng-select id="cityId" name="cityId" [multiple]=false formControlName="cityId" (change)="loadAllAreas()">
        <ng-option *ngFor="let city of cities" [value]="city.id"> {{city.translations[0].name}}
        </ng-option>
      </ng-select>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-3 label-control" for="areaId">{{'Area' | translate}}</label>
    <div class="col-md-9">
      <ng-select id="areaId" name="areaId" [multiple]=false formControlName="areaId">
        <ng-option *ngFor="let area of areas" [value]="area.id"> {{area.translations[0].name}}
        </ng-option>
      </ng-select>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-3 label-control" for="locationLink">{{'Location Link' | translate}}</label>
    <div class="col-md-9">
      <input type="text" id="locationLink" class="form-control" name="locationLink"
        placeholder="{{'Location Link' | translate}}" formControlName="locationLink" (ngModelChange)="onLinkChanges()">
    </div>
  </div>

  <div class=" form-group row">
    <div class="col-sm-12">
      <label for="search_bar"> {{"Search" | translate}} </label>
      <input class="form-control label-control" ngx-google-places-autocomplete type="text" name="search_bar"
        placeholder="{{'Search For Your Location' | translate}}" #placesRef="ngx-places" formControlName="search_bar"
        id="search_bar" (onAddressChange)="handleAddressChange($event)">
    </div>

    <div class="col-sm-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body mt-2">
            <agm-map [latitude]="locationForm.value.lat" [longitude]="locationForm.value.lng">
              <agm-marker [draggable]="true" [latitude]="locationForm.value.lat" [longitude]="locationForm.value.lng">
              </agm-marker>
            </agm-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
