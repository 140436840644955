import { Routes, RouterModule } from "@angular/router";

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../pages/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "role",
    loadChildren: () =>
      import("../../pages/role/role.module").then((m) => m.RoleModule),
  },
  {
    path: "user",
    loadChildren: () =>
      import("../../pages/user/user.module").then((m) => m.UserModule),
  },
  {
    path: "notification",
    loadChildren: () =>
      import("../../pages/notification/notification.module").then(
        (m) => m.NotificationModule
      ),
  },
  {
    path: "year",
    loadChildren: () =>
      import("../../pages/year/year.module").then((m) => m.YearModule),
  },
  {
    path: "motor",
    loadChildren: () =>
      import("../../pages/motor/motor.module").then((m) => m.MotorModule),
  },
  {
    path: "transmission",
    loadChildren: () =>
      import("../../pages/transmission/transmission.module").then(
        (m) => m.TransmissionModule
      ),
  },
  {
    path: "brand",
    loadChildren: () =>
      import("../../pages/brand/brand.module").then((m) => m.BrandModule),
  },
  {
    path: "model",
    loadChildren: () =>
      import("../../pages/model/model.module").then((m) => m.ModelModule),
  },
  {
    path: "category",
    loadChildren: () =>
      import("../../pages/category/category.module").then(
        (m) => m.CategoryModule
      ),
  },
  {
    path: "language",
    loadChildren: () =>
      import("../../pages/language/language.module").then(
        (m) => m.LanguageModule
      ),
  },
  {
    path: "location",
    loadChildren: () =>
      import("../../pages/location/location.module").then(
        (m) => m.LocationModule
      ),
  },
  {
    path: "promo-code",
    loadChildren: () =>
      import("../../pages/promo-code/promo-code.module").then(
        (m) => m.PromoCodeModule
      ),
  },
  {
    path: "service",
    loadChildren: () =>
      import("../../pages/service/service.module").then((m) => m.ServiceModule),
  },
  {
    path: "car",
    loadChildren: () =>
      import("../../pages/car/car.module").then((m) => m.CarModule),
  },
  {
    path: "maintenance-center",
    loadChildren: () =>
      import("../../pages/maintenance-center/maintenance-center.module").then(
        (m) => m.MaintenanceCenterModule
      ),
  },
  {
    path: "spare-part-shop",
    loadChildren: () =>
      import("../../pages/spare-part-shop/spare-part-shop.module").then(
        (m) => m.SparePartShopModule
      ),
  },
  {
    path: "spare-part",
    loadChildren: () =>
      import("../../pages/spare-part/spare-part.module").then(
        (m) => m.SparePartModule
      ),
  },
  {
    path: "branch",
    loadChildren: () =>
      import("../../pages/branch/branch.module").then((m) => m.BranchModule),
  },
  {
    path: "setting",
    loadChildren: () =>
      import("../../pages/setting/setting.module").then((m) => m.SettingModule),
  },
  {
    path: "order",
    loadChildren: () =>
      import("../../pages/order/order.module").then((m) => m.OrderModule),
  },
  {
    path: "payment",
    loadChildren: () =>
      import("../../pages/payment/payment.module").then((m) => m.PaymentModule),
  },
  {
    path: "banner",
    loadChildren: () =>
      import("../../pages/banner/banner.module").then((m) => m.BannerModule),
  },
  {
    path: "fennex-market",
    loadChildren: () =>
      import("../../pages/fennex-market/fennex-market.module").then((m) => m.FennexMarketModule),
  },

];
