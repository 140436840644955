<div class="modal-header">
  <h4 class="modal-title">{{ "addNewOrderItem" | translate }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <i class="ft-x"> </i>
  </button>
</div>

<div class="modal-body">
  <form
    [formGroup]="orderItemForm"
    novalidate
    class="striped-rows"
  >
    <div *ngIf="sparePartShopId">
      <div class="form-group row" >
        <label class="col-md-3 label-control" for="sparePartId">{{
          "SparePart" | translate
        }}</label>
        <div class="col-md-9" style="height: 40px;">
          <ng-select
            id="sparePartId"
            name="sparePartId"
            formControlName="sparePartId"
            (scrollToEnd)="onScrollToEnd()"
            [loading]="isSelectLoading"
          >
            <ng-option
              *ngFor="let sparePart of spareParts"
              [value]="sparePart.id"
            >
              {{ sparePart.translations[0].name }}
            </ng-option>
          </ng-select>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-md-3 label-control" for="quantity">{{
          "Quantity" | translate
        }}</label>
        <div class="col-md-9">
          <input
            type="number"
            id="quantity"
            class="form-control"
            name="quantity"
            placeholder="{{ 'quantity' | translate }}"
            formControlName="quantity"
          />
        </div>
      </div>
    </div>

    <div *ngIf="maintenanceCenterId">
      <div class="form-group row" >
        <label class="col-md-3 label-control" for="serviceId">{{
          "Service" | translate
        }}</label>
        <div class="col-md-9" style="height: 40px;">
          <ng-select
            id="serviceId"
            name="serviceId"
            formControlName="serviceId"
            [loading]="isSelectLoading"
          >
            <ng-option
              *ngFor="let service of services"
              [value]="service.id"
            >
              {{ service.translations[0].name }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>

  </form>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
  >
    {{ "Cancel" | translate }}
  </button>

  <button
    type="button"
    class="btn btn-outline-success"
    (click)="onSubmit()"
  >
    {{ "Save" | translate }}
  </button>
</div>
