import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Injectable } from "@angular/core";
import swal, { SweetAlertIcon } from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  constructor(
    private ngxSpinner: NgxSpinnerService,
    private translate: TranslateService
  ) {}

  showSpinner() {
    this.ngxSpinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
  }
  hideSpinner() {
    this.ngxSpinner.hide();
  }

  fireAlert(title: string, message: string, alertType: SweetAlertIcon) {
    this.translate.get([title, message]).subscribe((data) => {
      swal.fire({
        title: data[title],
        text: data[message],
        icon: alertType,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    });
  }

  fireToast(message: string, alertType: SweetAlertIcon) {
    const Toast = swal.mixin({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", swal.stopTimer);
        toast.addEventListener("mouseleave", swal.resumeTimer);
      },
    });
    this.translate.get(message).subscribe((data) => {
      Toast.fire({
        icon: alertType,
        title: data,
      });
    });
  }

  fireAlertWithAction(
    title: string,
    message: string,
    alertType: SweetAlertIcon
  ) {
    return new Promise<any>((resolve, reject) => {
      this.translate
        .get([title, message, "Cancel", "Yes, Sure"])
        .subscribe((data) => {
          const result = swal.fire({
            title: data[title],
            text: data[message],
            icon: alertType,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: data["Yes, Sure"],
            cancelButtonText: data["Cancel"],
          });
          resolve(result);
        });
    });
  }

  //Images Helper Function
  convertFilesIntoImages(files: []) {
    return new Promise((resolve, reject) => {
      let images = [];
      for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];
        let reader: FileReader = new FileReader();
        reader.onload = () => {
          const base64 = reader.result.toString();
          images.push(base64.split(",")[1]);
          if (i == files.length - 1) {
            resolve(images);
          }
        };
        reader.readAsDataURL(currentFile);
      }
    });
  }
}
