import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceCenterService {

  constructor(private http: HttpClient) {}

  index(filters: any) {
    const URL = `${environment.baseUrl}/maintenance-center`;
    return this.http.get(URL, { params: filters });
  }

  getDetails(id) {
    const URL = `${environment.baseUrl}/maintenance-center/${id}`;
    return this.http.get(URL);
  }

  update(id, data) {
    const URL = `${environment.baseUrl}/maintenance-center/${id}`;
    return this.http.put(URL, data);
  }

  remove(id) {
    const URL = `${environment.baseUrl}/maintenance-center/${id}`;
    return this.http.delete(URL);
  }

  create(postData) {
    const URL = `${environment.baseUrl}/maintenance-center`;
    return this.http.post(URL, postData);
  }

  changeStatus(postData){
    const URL = `${environment.baseUrl}/maintenance-center/status`;
    return this.http.post(URL, postData);
  }
}
