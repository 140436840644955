import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http : HttpClient) { }

  index(filters: any) {
    const URL = `${environment.baseUrl}/push-notification`;
    return this.http.get(URL, { params: filters });
  }


  send(postData) {
    const URL = `${environment.baseUrl}/push-notification/send`;
    return this.http.post(URL, postData);
  }
}
