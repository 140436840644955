import { FormControl, Validators } from "@angular/forms";
import { FormControlName, FormGroup } from "@angular/forms";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterItem, FilterItemType } from "./filter.dto";

@Component({
  selector: "app-filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit {
  @Input() name: string;
  @Input() description: string;
  @Input() items: FilterItem[];

  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  @Output() onReset: EventEmitter<any> = new EventEmitter();

  filterForm: FormGroup;

  filterItemType = FilterItemType
  constructor() {}

  ngOnInit(): void {
    let formGroupItems = {};
    for (let i = 0; i < this.items.length; i++) {
      formGroupItems[this.items[i].propName] = new FormControl(
        this.items[i].currentValue
      );
    }
    this.filterForm = new FormGroup(formGroupItems);
  }

  onSubmit() {

    this.onFilter.emit(this.filterForm.value);
  }
  onClear() {
    this.filterForm.reset();
    this.onReset.emit();
  }
}
