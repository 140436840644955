export enum FilterItemType {
  TEXT,
  LIST,
  DATE,
}

export class FilterItem {
  displayName: string;
  propName: string;
  type: FilterItemType;
  required: boolean = false;
  currentValue?: any;
  values?: any[];
  selectValue? : string;
  displayValue? : any;
  multiSelect?: boolean = false;
}
