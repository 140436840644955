<div class="card">
  <div class="card-header">
    <h4 class="card-title">{{ name }}</h4>
  </div>
  <div class="card-content">
    <div class="card-body">
      <div class="card-text">
        <p class="card-text">{{ description }}.</p>
      </div>
      <form
        class="form"
        [formGroup]="filterForm"
        novalidate
        (submit)="onSubmit()"
      >
        <div class="row mt-2">
          <ng-container *ngFor="let item of items">
            <div class="col-md-6">
              <div *ngIf="item.type == filterItemType.TEXT" class="form-group">
                <label [for]="item.propName">
                  {{ item.displayName | translate }}
                </label>
                <input
                  type="text"
                  [id]="item.propName"
                  class="form-control"
                  [placeholder]="item.displayName"
                  [name]="item.propName"
                  [formControlName]="item.propName"
                />
              </div>

              <div *ngIf="item.type == filterItemType.LIST" class="form-group">
                <label [for]="item.propName">
                  {{ item.displayName | translate }}
                </label>
                <ng-select
                  [id]="item.propName"
                  [formControlName]="item.propName"
                  [multiple]="item.multiSelect"
                >
                  <ng-option
                    *ngFor="let subItem of item.values"
                    [value]="
                      item.selectValue ? subItem[item.selectValue] : subitem
                    "
                    >{{
                      (item.displayValue ? subItem[item.displayValue] : subItem)
                        | translate
                    }}</ng-option
                  >
                </ng-select>
              </div>

              <div *ngIf="item.type == filterItemType.DATE" class="form-group">
                <label [for]="item.propName">
                  {{ item.displayName | translate }}
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    placeholder="YYYY-MM-DD"
                    [name]="item.propName"
                    [formControlName]="item.propName"
                    ngbDatepicker
                    #d2="ngbDatepicker"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text" (click)="d2.toggle()">
                      <i class="fa fa-calendar" style="cursor: pointer"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div>
          <button type="submit" class="btn btn-primary mr-1">
            <i class="ft-check mr-2"></i>{{ "Filter" | translate }}
          </button>
          <button type="button" class="btn btn-secondary" (click)="onClear()">
            <i class="ft-x mr-2"></i>{{ "Clear" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
