import { MaintenanceCenterService } from "app/shared/services/maintenance-center.service";
import { HelperService } from "app/shared/services/helper.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { SparePartService } from "app/shared/services/spare-part.service";
import { OrderService } from "./../../services/order.service";
import { Input } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-order-item-create",
  templateUrl: "./order-item-create.component.html",
  styleUrls: ["./order-item-create.component.scss"],
})
export class OrderItemCreateComponent implements OnInit {
  @Input() orderId: any;
  @Input() maintenanceCenterId: any;
  @Input() sparePartShopId: any;

  isSelectLoading = true;

  filterData = {
    offset: 0,
    limit: 10,
  };

  spareParts = [];
  services = [];

  orderItemForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private orderService: OrderService,
    private sparePartService: SparePartService,
    private helperService: HelperService,
    private maintenanceCenterService: MaintenanceCenterService
  ) {}
  ngOnInit(): void {
    this.orderItemForm = new FormGroup({
      sparePartId: new FormControl(null, []),
      serviceId: new FormControl(null, []),
      quantity: new FormControl(1, []),
      orderId: new FormControl(this.orderId, [Validators.required]),
    });

    if (this.sparePartShopId) {
      this.loadSpareParts();
    } else {
      this.loadServices();
    }
  }

  loadSpareParts() {
    this.isSelectLoading = true;
    this.filterData["sparePartShopId"] = this.sparePartShopId;
    this.sparePartService.index(this.filterData).subscribe(
      (data) => {
        this.isSelectLoading = false;
        this.spareParts = this.spareParts.concat(data["data"]["rows"]);
      },
      (err) => {
        this.isSelectLoading = false;
      }
    );
  }

  loadServices() {
    this.isSelectLoading = true;
    this.maintenanceCenterService
      .getDetails(this.maintenanceCenterId)
      .subscribe(
        (data) => {
          this.isSelectLoading = false;
          this.services = data["data"]["services"];
        },
        (err) => {
          this.isSelectLoading = false;
        }
      );
  }

  onScrollToEnd() {
    this.filterData.offset++;
    if (this.sparePartShopId) {
      this.loadSpareParts();
    } else {
      // this.loadServices();
    }
  }

  onSubmit() {
    const postBody = this.orderItemForm.value;
    this.helperService.showSpinner();
    if (
      (this.sparePartShopId && !postBody["sparePartId"]) ||
      !postBody["quantity"] ||
      (this.maintenanceCenterId && !postBody["serviceId"])
    ) {
      this.helperService.fireToast("Please fill the required data", "error");
      return;
    }

    if (this.sparePartShopId) {
      postBody["sparePartShopId"] = this.sparePartShopId;
    } else {
      postBody["maintenanceCenterId"] = this.maintenanceCenterId;
    }

    this.orderService.addOrderItem(postBody).subscribe(
      (data) => {
        this.helperService.hideSpinner();
        this.activeModal.close("DONE");
      },
      (err) => {
        this.helperService.hideSpinner();
        this.helperService.fireToast(
          "Something Weird Happened Please Contact Support",
          "error"
        );
      }
    );
  }
}
