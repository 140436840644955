import { LocationService } from "./../../services/location.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { HelperService } from "app/shared/services/helper.service";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { environment } from "environments/environment";
declare var google: any;

@Component({
  selector: "app-location-create",
  templateUrl: "./location-create.component.html",
  styleUrls: ["./location-create.component.scss"],
})
export class LocationCreateComponent implements OnInit, OnChanges {
  @Input() locationForm: FormGroup;
  @Input() initialData;

  @Output() onFormUpdated: EventEmitter<any> = new EventEmitter();

  cities = [];
  areas = [];
  constructor(
    private locationService: LocationService,
    private helperService: HelperService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.locationForm = new FormGroup({
      id: new FormControl(),
      nickname: new FormControl(null, [Validators.required]),
      lat: new FormControl(null, [Validators.required]),
      lng: new FormControl(null, [Validators.required]),
      addressLine1: new FormControl(null, [Validators.required]),
      addressLine2: new FormControl(null, [Validators.required]),
      areaId: new FormControl(null, [Validators.required]),
      cityId: new FormControl(null, [Validators.required]),
      search_bar: new FormControl(),
      locationLink: new FormControl(),
    });

    this.locationForm.valueChanges.subscribe((valueChanged) => {
      this.onFormUpdated.emit(this.locationForm);
    });
    this.loadUserCurrrentLocation();
    this.loadAllCities();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.locationForm && this.initialData) {
      this.locationForm.patchValue(this.initialData);
    }
  }

  loadUserCurrrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.locationForm.patchValue({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        if (this.initialData) {
          this.locationForm.patchValue(this.initialData);
        }
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  handleAddressChange(address: Address) {
    this.locationForm.patchValue({
      lat: address.geometry.location.lat(),
      lng: address.geometry.location.lng(),
    });
  }

  loadAllCities() {
    this.locationService
      .indexCity({
        offset: 0,
        limit: 10000,
        languageCode: environment.language,
      })
      .subscribe(
        (data) => {
          this.cities = data["data"]["rows"];
        },
        (err) => {
          this.helperService.fireToast(
            "Something Weird Happened Please Contact Support",
            "error"
          );
        }
      );
  }

  loadAllAreas() {
    this.locationService
      .indexArea({
        offset: 0,
        limit: 10000,
        languageCode: environment.language,
        cityId: this.locationForm.value["cityId"],
      })
      .subscribe(
        (data) => {
          this.areas = data["data"]["rows"];
        },
        (err) => {
          this.helperService.fireToast(
            "Something Weird Happened Please Contact Support",
            "error"
          );
        }
      );
  }

  onLinkChanges(){
    const [lat , lng] = this.extractLatAndLng(this.locationForm.value['locationLink']);
    if (lat && lng){
      this.locationForm.patchValue({lat , lng})
    }
  }

  onSubmit() {
    if (!this.locationForm.valid) {
      this.helperService.fireToast("Please fill the required data", "error");
      return;
    }
    this.helperService.showSpinner();
    let postData = this.locationForm.value;
    this.locationService.createSavedLocation(postData).subscribe(
      (data) => {
        this.helperService.hideSpinner();
        this.helperService.fireAlert(
          "Success",
          "The Item has been created successfully",
          "success"
        );
        this.router.navigate(["/branch"]);
      },
      (err) => {
        this.helperService.hideSpinner();
        this.helperService.fireToast(
          "Something Weird Happened Please Contact Support",
          "error"
        );
      }
    );
  }

  extractLatAndLng(url) {
    var lat, lng, splitLatLng, splitUrl, latLong;
    var regex = new RegExp('@(.*),(.*),');
    var lon_lat_match = url.match(regex);
    lng = lon_lat_match[1];
    lat = lon_lat_match[2];
    return [lat, lng];
  }
}
