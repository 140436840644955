import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MessagingService } from './shared/services/messaging.service';
import { Component, ViewContainerRef, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(private router: Router, private messagingService : MessagingService , private translate : TranslateService ) {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
  }

  ngOnInit() {
    this.translate.setDefaultLang('ar');
    this.translate.use('ar');
    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
