<div class="modal-header">
  <h4 class="modal-title">Change An item Status</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form class="form" [formGroup]="statusForm">
    <div class="form-group row">
      <label class="col-md-3 label-control" for="status">Status</label>
      <div class="col-md-9">
        <ng-select id="status" name="status" disabled="true" [multiple]=false formControlName="status">
          <ng-option *ngFor="let status of availableStatus" [value]="status"> {{status}} </ng-option>
        </ng-select>
      </div>
    </div>
  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Close click')">Close</button>
  <button type="button" class="btn btn-primary" (click)="onSaveClicked()">SAVE</button>
</div>
