// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // baseUrl: "https://api.fennexapp.com/api",
  // imageBaseUrl: "https://api.fennexapp.com/images",
  // imageBaseUrl: "https://api.fennexapp.com/excel",

  // baseUrl: "http://localhost:3000/api",
  // imageBaseUrl: "http://localhost:3000/images",
  excelBaseUrl: "http://localhost:3000/excel",

  baseUrl: "http://localhost:3000/api",
  imageBaseUrl: "https://api.fennexapp.com/images",
  language: "AR",
};
