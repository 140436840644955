import { HelperService } from "app/shared/services/helper.service";
import { Validators } from "@angular/forms";
import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-change-status",
  templateUrl: "./change-status.component.html",
  styleUrls: ["./change-status.component.scss"],
})
export class ChangeStatusComponent implements OnInit {
  @Input() currentStatus: string;
  @Input() availableStatus: string[];

  statusForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.statusForm = new FormGroup({
      status: new FormControl(this.currentStatus, [Validators.required]),
    });
  }

  onSaveClicked() {
    if (!this.statusForm.valid) {
      this.helperService.fireToast(
        "Please fill the required Status data",
        "error"
      );
      return;
    }
    this.activeModal.close(this.statusForm.value);
  }
}
