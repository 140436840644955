import { AuthService } from "./../auth/auth.service";
import { HelperService } from "./helper.service";
import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private helperService: HelperService,
    private authService: AuthService
  ) {
    // this.angularFireMessaging.onBackgroundMessage.subscribe((_messaging) => {
    //   _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //   _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    // });
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        localStorage.setItem("fcm_token", token);
      },
      (err) => {}
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(async (payload) => {
      this.currentMessage.next(payload);
      if (await this.authService.isAuthenticated()) {
        this.authService.numberOfActiveNotifications++;
        this.helperService.fireToast(payload["notification"].body, "info");
      }
    });
  }
}
