import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  index(filters: any) {
    const URL = `${environment.baseUrl}/order`;
    return this.http.get(URL, { params: filters });
  }

  getDetails(id) {
    const URL = `${environment.baseUrl}/order/${id}`;
    return this.http.get(URL);
  }

  update(id, data) {
    const URL = `${environment.baseUrl}/order/${id}`;
    return this.http.put(URL, data);
  }

  remove(id) {
    const URL = `${environment.baseUrl}/order/${id}`;
    return this.http.delete(URL);
  }

  create(postData) {
    const URL = `${environment.baseUrl}/order`;
    return this.http.post(URL, postData);
  }

  addOrderNote(postData) {
    const URL = `${environment.baseUrl}/order/note`;
    return this.http.post(URL, postData);
  }

  updateTotalAmount(postData) {
    const URL = `${environment.baseUrl}/order/amount`;
    return this.http.post(URL, postData);
  }

  updateProcessingDate(postData) {
    const URL = `${environment.baseUrl}/order/processingDate`;
    return this.http.post(URL, postData);
  }

  changeStatus(postData) {
    const URL = `${environment.baseUrl}/order/status`;
    return this.http.post(URL, postData);
  }

  exportExcel(orderToIndex) {
    const URL = `${environment.baseUrl}/order/export/excel`;
    return this.http.get(URL, { params: orderToIndex });
  }

  removeOrderItem(orderItemToRemove){
    const URL = `${environment.baseUrl}/order/item/remove`;
    return this.http.post(URL, orderItemToRemove);
  }

  addOrderItem(orderItemToAdd){
    const URL = `${environment.baseUrl}/order/item/add`;
    return this.http.post(URL, orderItemToAdd);
  }
}
