import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  constructor(private http: HttpClient) {}

  //Country Service
  indexCountry(filters: any) {
    const URL = `${environment.baseUrl}/country`;
    return this.http.get(URL, { params: filters });
  }

  getDetailsCountry(id) {
    const URL = `${environment.baseUrl}/country/${id}`;
    return this.http.get(URL);
  }

  updateCountry(id, data) {
    const URL = `${environment.baseUrl}/country/${id}`;
    return this.http.put(URL, data);
  }

  removeCountry(id) {
    const URL = `${environment.baseUrl}/country/${id}`;
    return this.http.delete(URL);
  }

  createCountry(postData) {
    const URL = `${environment.baseUrl}/country`;
    return this.http.post(URL, postData);
  }

  //City service
  indexCity(filters: any) {
    const URL = `${environment.baseUrl}/city`;
    return this.http.get(URL, { params: filters });
  }

  getDetailsCity(id) {
    const URL = `${environment.baseUrl}/city/${id}`;
    return this.http.get(URL);
  }

  updateCity(id, data) {
    const URL = `${environment.baseUrl}/city/${id}`;
    return this.http.put(URL, data);
  }

  removeCity(id) {
    const URL = `${environment.baseUrl}/city/${id}`;
    return this.http.delete(URL);
  }

  createCity(postData) {
    const URL = `${environment.baseUrl}/city`;
    return this.http.post(URL, postData);
  }

  //Area service
  indexArea(filters: any) {
    const URL = `${environment.baseUrl}/area`;
    return this.http.get(URL, { params: filters });
  }

  getDetailsArea(id) {
    const URL = `${environment.baseUrl}/area/${id}`;
    return this.http.get(URL);
  }

  updateArea(id, data) {
    const URL = `${environment.baseUrl}/area/${id}`;
    return this.http.put(URL, data);
  }

  removeArea(id) {
    const URL = `${environment.baseUrl}/area/${id}`;
    return this.http.delete(URL);
  }

  createArea(postData) {
    const URL = `${environment.baseUrl}/area`;
    return this.http.post(URL, postData);
  }

  //SAved Location service
  indexSavedLocation(filters: any) {
    const URL = `${environment.baseUrl}/saved-location`;
    return this.http.get(URL, { params: filters });
  }

  getDetailsSavedLocation(id) {
    const URL = `${environment.baseUrl}/saved-location/${id}`;
    return this.http.get(URL);
  }

  updateSavedLocation(id, data) {
    const URL = `${environment.baseUrl}/saved-location/${id}`;
    return this.http.put(URL, data);
  }

  removeSavedLocation(id) {
    const URL = `${environment.baseUrl}/saved-location/${id}`;
    return this.http.delete(URL);
  }

  createSavedLocation(postData) {
    const URL = `${environment.baseUrl}/saved-location`;
    return this.http.post(URL, postData);
  }
}
