import { HelperService } from "./../services/helper.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { ApplicationRef, ChangeDetectorRef, Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "environments/environment";
import { updateNamedExports } from "typescript";


@Injectable()
export class AuthService {
  public user = new BehaviorSubject({} as any);
  public userDetails = null;

  numberOfActiveNotifications = 0;
  permissions = [];
  permissionOBJ;

  constructor(
    public _firebaseAuth: AngularFireAuth,
    public router: Router,
    private http: HttpClient,
    private helperService: HelperService,
    private changeDetector : ApplicationRef
  ) {}

  signInUser(login: string, password: string, device: any) {
    let URL = `${environment.baseUrl}/auth/login`;
    return this.http.post(URL, { login, password, device });
  }

  logout() {
    localStorage.removeItem('token');
    this.permissionOBJ = undefined;
    this.router.navigate(['/pages/login']);
    location.reload();
  }

  private getMe(token: string) {
    let URL = `${environment.baseUrl}/auth/me`;
    return this.http.get(URL, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  isAuthenticated() {
    return new Promise((resolve, reject) => {
      let token = localStorage.getItem("token");
      if (!token) {
        resolve(false);
      }
      this.getMe(token).subscribe(
        (data) => {
          this.userDetails = data["data"];
          this.buildPermissions();
          this.user.next(data["data"]);
          resolve(true);
        },
        (err) => {
          this.helperService.fireAlert(
            "Wrong Access",
            "your Session has been expired",
            "error"
          );
          resolve(false);
        }
      );
    });
  }

  private buildPermissions() {
    this.permissionOBJ = {};
    this.userDetails.roles.map((role) => {
      this.permissions = this.permissions.concat(role.permissions);
    });
    this.permissions.map((permission) => {
      if (!this.permissionOBJ[permission.module])
        this.permissionOBJ[permission.module] = {};
      this.permissionOBJ[permission.module][permission.action] = true;
    });
    console.log(this.permissionOBJ);
  }

  checkIfHasAccess(module: string, action: string) : boolean {
    if (!this.permissionOBJ){
      this.buildPermissions();
    }
    if (module && action){
      return (this.permissionOBJ[module] && this.permissionOBJ[module][action])
    } else if (module){
      return this.permissionOBJ[module]
    } else if (!module && !action){
      return true;
    }
  }
}
