import { RouteInfo } from "./vertical-menu.metadata";

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "ft-home",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "dahboard-report",
    action: "",
    submenu: [],
  },
  {
    path: "/fennex-market/market",
    title: "Fennex Market",
    icon: "ft-shopping-cart",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "SparePart",
    action: "getMarketItems",
    submenu: [],
  },
  {
    path: "",
    title: "Users",
    icon: "ft-user",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "user",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/user/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "user",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/user/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "user",
        action: "create",
        submenu: [],
      },
      {
        path: "/user/role-assign",
        title: "Assign Role",
        icon: "ft-plus-square",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "user",
        action: "assignRole",
        submenu: [],
      },
      {
        path: "/user/role-unassign",
        title: "UnAssign Role",
        icon: "ft-delete",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "user",
        action: "unassignRole",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Roles",
    icon: "ft-command",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "role",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/role/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "role",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/role/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "role",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Cars",
    icon: "fas fa-car",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "car",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/car/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "car",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/car/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "car",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "SpareParts",
    icon: "fas fa-cogs",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "SparePart",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/spare-part/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "SparePart",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/spare-part/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "SparePart",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Maintenance Centers",
    icon: "fas fa-tools",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "maintenance-center",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/maintenance-center/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "maintenance-center",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/maintenance-center/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "maintenance-center",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "SparePart Shops",
    icon: "fas fa-store-alt",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "spare-part-shop",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/spare-part-shop/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "spare-part-shop",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/spare-part-shop/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "spare-part-shop",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Branches",
    icon: "fas fa-place-of-worship",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "branch",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/branch/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "branch",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/branch/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "branch",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Orders",
    icon: "fab fa-first-order-alt",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "order",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/order/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "order",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/order/pending",
        title: "Pending",
        icon: "fas fa-pause-circle",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "order",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/order/approved",
        title: "Approved",
        icon: "fas fa-thumbs-up",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "order",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/order/delivered",
        title: "Delivered",
        icon: "fas fa-check-double",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "order",
        action: "getAll",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Payments",
    icon: "fas fa-money-bill-wave",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "payment",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/payment/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "payment",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/payment/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "payment",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Notifications",
    icon: "ft-bell",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "notification",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "",
        title: "Trigger",
        icon: "ft-anchor",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "trigger",
        action: "showOnDashboardMenu",
        submenu: [
          {
            path: "/notification/trigger/show",
            title: "Show",
            icon: "ft-eye",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            module: "trigger",
            action: "get_all",
            submenu: [],
          },
        ],
      },
      {
        path: "",
        title: "Template",
        icon: "ft-book",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "template",
        action: "showOnDashboardMenu",
        submenu: [
          {
            path: "/notification/template/show",
            title: "Show",
            icon: "ft-eye",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            module: "template",
            action: "getAll",
            submenu: [],
          },
          {
            path: "/notification/template/create",
            title: "Create",
            icon: "ft-file-plus",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            module: "template",
            action: "create",
            submenu: [],
          },
        ],
      },
      {
        path: "",
        title: "Push-Notification",
        icon: "ft-phone-call",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "pushNotification",
        action: "showOnDashboardMenu",
        submenu: [
          {
            path: "/notification/push-notification/show",
            title: "Show",
            icon: "ft-eye",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            module: "pushNotification",
            action: "getAll",
            submenu: [],
          },
          {
            path: "/notification/push-notification/send",
            title: "Send",
            icon: "ft-send",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            module: "pushNotification",
            action: "sendNotification",
            submenu: [],
          },
        ],
      },
      {
        path: "/notification/send-notification",
        title: "Send-Notification",
        icon: "ft-bell",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "trigger",
        action: "sendingRegularNotification",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Years",
    icon: "ft-calendar",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "year",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/year/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "year",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/year/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "year",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Motors",
    icon: "ft-battery-charging",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "motor",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/motor/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "motor",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/motor/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "motor",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Transmissions",
    icon: "ft-settings",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "transmission",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/transmission/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "transmission",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/transmission/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "transmission",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Brands",
    icon: "ft-aperture",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "brand",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/brand/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "brand",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/brand/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "brand",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Models",
    icon: "ft-disc",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "model",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/model/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "model",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/model/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "model",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Services",
    icon: "fas fa-concierge-bell",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "service",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/service/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "service",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/service/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "service",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Categories",
    icon: "ft-list",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "category",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/category/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "category",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/category/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "category",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Promo Codes",
    icon: "ft-percent",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "promoCode",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/promo-code/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "promoCode",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/promo-code/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "promoCode",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Languages",
    icon: "ft-globe",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "language",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/language/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "language",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/language/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "language",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Locations",
    icon: "ft-map",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "locationTab",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "",
        title: "Countries",
        icon: "ft-map",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "country",
        action: "showOnDashboardMenu",
        submenu: [
          {
            path: "/location/country/show",
            title: "Show",
            icon: "ft-eye",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            module: "country",
            action: "getAll",
            submenu: [],
          },
          {
            path: "/location/country/create",
            title: "Create",
            icon: "ft-file-plus",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            module: "country",
            action: "create",
            submenu: [],
          },
        ],
      },
      {
        path: "",
        title: "Cities",
        icon: "ft-map",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "city",
        action: "showOnDashboardMenu",
        submenu: [
          {
            path: "/location/city/show",
            title: "Show",
            icon: "ft-eye",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            module: "city",
            action: "getAll",
            submenu: [],
          },
          {
            path: "/location/city/create",
            title: "Create",
            icon: "ft-file-plus",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            module: "city",
            action: "create",
            submenu: [],
          },
        ],
      },
      {
        path: "",
        title: "Areas",
        icon: "ft-map",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "area",
        action: "showOnDashboardMenu",
        submenu: [
          {
            path: "/location/area/show",
            title: "Show",
            icon: "ft-eye",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            module: "area",
            action: "getAll",
            submenu: [],
          },
          {
            path: "/location/area/create",
            title: "Create",
            icon: "ft-file-plus",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            module: "area",
            action: "create",
            submenu: [],
          },
        ],
      },
      // {
      //   path: "",
      //   title: "Saved Locations",
      //   icon: "ft-map",
      //   class: "has-sub",
      //   badge: "",
      //   badgeClass: "",
      //   isExternalLink: false,
      //   submenu: [
      //     {
      //       path: "/location/saved-location/show",
      //       title: "Show",
      //       icon: "ft-eye",
      //       class: "",
      //       badge: "",
      //       badgeClass: "",
      //       isExternalLink: false,
      //       submenu: [],
      //     },
      //     {
      //       path: "/location/saved-location/create",
      //       title: "Create",
      //       icon: "ft-file-plus",
      //       class: "",
      //       badge: "",
      //       badgeClass: "",
      //       isExternalLink: false,
      //       submenu: [],
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "",
    title: "Banners",
    icon: "ft-globe",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "banner",
    action: "showOnDashboardMenu",
    submenu: [
      {
        path: "/banner/show",
        title: "Show",
        icon: "ft-eye",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "banner",
        action: "getAll",
        submenu: [],
      },
      {
        path: "/banner/create",
        title: "Create",
        icon: "ft-file-plus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        module: "banner",
        action: "create",
        submenu: [],
      },
    ],
  },
  {
    path: "/setting",
    title: "Settings",
    icon: "fas fa-sliders-h",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    module: "setting",
    action: "getAll",
    submenu: [],
  },
];
